var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',{staticClass:"justify-content-end"},[_c('b-col',{staticClass:"d-flex align-items-center justify-content-end mb-1 mb-md-0",attrs:{"cols":"12","md":"3"}},[_c('b-button',{attrs:{"variant":"primary","to":{ name: 'apps-transactions-purchase-order-add'}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.$t('apps.transactions.purchase.order.actions.create'))+" ")],1)],1)],1)],1),_c('b-table',{ref:"refListTable",staticClass:"position-relative",attrs:{"id":"refListTable","no-provider-paging":"","no-provider-filtering":"","fields":_vm.tableColumns,"items":_vm.itemLists,"responsive":"","primary-key":"id","show-empty":"","busy":_vm.isBusy,"empty-text":_vm.$t('globalSingular.noMatching'),"sort-desc":_vm.isSortDirDesc},on:{"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2 d-flex flex-column justify-content-center"},[_c('div',{staticClass:"text mb-1"},[_c('b-spinner',{staticClass:"align-middle"})],1),_c('strong',[_vm._v(_vm._s(_vm.$t('globalActions.fetchingDataAPI')))])])]},proxy:true},{key:"head()",fn:function(ref){
var field = ref.field;
return [_c('table-search-column',{attrs:{"field":field,"fields":_vm.tableColumns,"focused-field":_vm.focusedSearchField},on:{"set-focused-field":function (value) { return _vm.focusedSearchField = value; },"search-data":function (params) { return _vm.searchData(params); }}})]}},{key:"cell(code)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{staticClass:"font-weight-bolder",attrs:{"to":{ name: 'apps-transactions-purchase-order-detail', params: { id: item.id } }}},[_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[_vm._v(" "+_vm._s(item.code)+" ")])])]}},{key:"cell(image)",fn:function(ref){
var item = ref.item;
return [(!!item.name_file)?_c('div',[_c('b-link',{staticClass:"font-weight-bolder",attrs:{"to":{ name: 'apps-transactions-purchase-order-listimage', params: { id: item.id } }}},[_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"21"}})],1)])],1):_c('span',[_vm._v("-")])]}},{key:"cell(pr)",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"cell(label)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[_vm._v(" "+_vm._s(item.contact.label)+" ")]),_c('small',{staticClass:"font-italic"},[_vm._v(_vm._s(item.remark))])]}},{key:"cell(sum_of_bill_amount)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.sum_of_bill_amount))+" ")])]}},{key:"cell(audit)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[_vm._v(" "+_vm._s(item.updated_process)+" By "+_vm._s(item.getUser.user_name)+" ")]),_c('small',{staticClass:"font-italic"},[_vm._v(_vm._s(new Date(item.updated_at).toDateString()))])]}},{key:"cell(tags)",fn:function(ref){
var item = ref.item;
return [((item.tags || []).length > 0)?_c('div',{staticClass:"d-flex"},_vm._l((item.tags),function(tag,index){return _c('b-badge',{key:index,attrs:{"pill":"","variant":"light-primary"}},[_vm._v(_vm._s(tag.name))])}),1):_c('span',[_vm._v("-")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('b-link',{staticClass:"font-weight-bolder",attrs:{"to":{ name: 'apps-transactions-purchase-order-prints-v2', params: { id: item.id } }}},[_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"21"}})],1)])],1)]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('globalSingular.showing'))+" "+_vm._s(_vm.dataMeta.from)+" "+_vm._s(_vm.$t('globalSingular.to'))+" "+_vm._s(_vm.dataMeta.to)+" "+_vm._s(_vm.$t('globalSingular.of'))+" "+_vm._s(_vm.dataMeta.of)+" "+_vm._s(_vm.$t('globalSingular.entries')))])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalList,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item","aria-controls":"refListTable"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }